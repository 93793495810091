import React from 'react';
import CountUp from 'react-countup';
import TrackVisibility from "react-on-screen";

const Data = [
    {
        countNum : 100,
        countTitle: 'Succesful Projects.',
    },
    {
        countNum : 100,
        countTitle: 'Customer Satisfaction.',
    },
    {
        countNum : 80,
        countTitle: 'Increase in Revenue',
    },
    {
        countNum : 95,
        countTitle: 'On Time Delivery Rate',
    },
  
];
const CounterUpFour = ({textALign, counterStyle, column}) => {
    return (
        <div className="row">
            {Data.map((data, index) => (
                <div className={`${column}`} key={index}>
                    <div className={`count-box ${counterStyle} ${textALign}`}>
                        <TrackVisibility once>
                            {({ isVisible }) => isVisible && 
                                <div className="count-number">{isVisible ? <CountUp end={data.countNum} /> : "%"}</div>}
                        </TrackVisibility>
                        <h5 className="counter-title">{data.countTitle}</h5>
                    </div>
                </div>
            ))}
        </div>
    )
}
export default CounterUpFour;
import React from "react";
import { FiCheck, FiStar } from "react-icons/fi";

const TechTitans = () => {
  return (
    <div className="advance-pricing">
      <div className="inner">
        <div className="row row--0">
          <div className="col-lg-6">
            <div className="pricing-left">
              {/* <h3 className="main-title">Active Plan Mode.</h3> */}
              <figure
                className=""
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  style={{ width: "180px" }}
                  src={`./images/testimonial/Group 209.png`}
                  alt="Corporate React Template"
                />
              </figure>
              <h3 className="main-title">Ajay Vaswani</h3>
              <p className="description">
                With more than two decades of experience as a strategic thinker
                and skilled manager, Ajay Vaswani is a renowned technology
                expert. He has successfully overseen high-impact programs from
                inception to completion, leaving a lasting impact on the
                modernization of monolithic applications and their
                transformation into cutting-edge technology stacks. Leading
                engineering organizations at multiple companies, his key
                specialties lie in collaborating with senior leadership,
                fostering cohesive teams, and driving organizational transitions
                towards enterprise agile practices.
              </p>
              
              {/* <div className="price-wrapper">
                <span className="price-amount">
                  $129 <sup>/mo</sup>
                </span>
              </div>
              <div className="pricing-btn-group">
                <button className="btn-default">Purchase Now</button>
                <button className="btn-default btn-border">Upgrade</button>
              </div>
              <div className="rating">
                <a href="#rating">
                  <FiStar />
                </a>
                <a href="#rating">
                  <FiStar />
                </a>
                <a href="#rating">
                  <FiStar />
                </a>
                <a href="#rating">
                  <FiStar />
                </a>
                <a href="#rating">
                  <FiStar />
                </a>
              </div>
              <span className="subtitle">
                rated 4.5/5 Stars in 1000+ reviews.
              </span> */}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="pricing-right">
              {/* <h3 className="main-title">Active Plan Mode.</h3> */}
              
              <figure
                className=""
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  style={{ width: "180px" }}
                  src={`./images/testimonial/Group 131.png`}
                  alt="Corporate React Template"
                />
              </figure>
              <h3 className="main-title">Siddhesh Shelar</h3>
              <p className="description">
                With a strong focus on DevOps and software automation, Siddhesh
                Shelar brings more than 16 years of extensive experience in the
                IT industry. His expertise spans across diverse sectors,
                including investment banking, financial services, e-learning,
                and advertising. As an authority in cloud computing, he excels
                in implementing innovative solutions and possesses exceptional
                testing and implementation abilities for AI models. Siddhesh is
                widely recognized across industries for his leadership in
                driving successful application transformations using
                cutting-edge technologies.
              </p>
              {/* <div className="price-wrapper">
                <span className="price-amount">
                  $129 <sup>/mo</sup>
                </span>
              </div>
              <div className="pricing-btn-group">
                <button className="btn-default">Purchase Now</button>
                <button className="btn-default btn-border">Upgrade</button>
              </div>
              <div className="rating">
                <a href="#rating">
                  <FiStar />
                </a>
                <a href="#rating">
                  <FiStar />
                </a>
                <a href="#rating">
                  <FiStar />
                </a>
                <a href="#rating">
                  <FiStar />
                </a>
                <a href="#rating">
                  <FiStar />
                </a>
              </div>
              <span className="subtitle">
                rated 4.5/5 Stars in 1000+ reviews.
              </span> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TechTitans;

import React from "react";
import { Link, useParams } from "react-router-dom";
import ServiceData from "../data/service/ServiceData.json";
import Layout from "../common/Layout";
import { FiFile, FiMapPin, FiPhone } from "react-icons/fi";

const ServiceDetails = () => {
  
  const { id } = useParams();
  const serviceItem = ServiceData.find((item) => item.id === parseInt(id, 10));



  if (!serviceItem) {
    return <div>Service not found</div>;
  }

  return (
   
  
<Layout>
      <div className="rwt-portfolio-details rn-section-gap">
        <div className="container">
          <div className="col-lg-10 offset-lg-1">
            <div className="row row--0 about-wrapper align-items-center ">
            <h4>{serviceItem.detailstitle}</h4>
              <div className="col-lg-6">
              
                <div className="thumbnail">
                   
                  <img
                    style={{ width: "410px" }}
                    src={serviceItem.image}
                    alt={serviceItem.title}
                  />
                </div>
              </div>
              <div className="col-lg-6 mt_md--30 mt_sm--30">
                <div className="content">
                  <div className="inner">
                    <div
                      className="details-list-description"
                      style={{
                        fontSize: "20px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {/* <h2>{serviceItem.detailstitle}</h2> */}
                      {/* <img src={serviceItem.image} alt={serviceItem.title} /> */}
                      <p>{serviceItem.description}</p>
                    </div>
                    {/* <h4 className="title">I'm Web Dedeloper</h4>
                                        <p>Web designer and developer working for envato.com in Paris, France.</p>
                                        <ul className="contact-address">
                                            <li><FiFile /> Web designer & developer</li>
                                            <li><FiPhone /> +01916395965</li>
                                            <li><FiMapPin /> Dhaka, Bangladesh</li>
                                        </ul> */}
                                        <div className="download-button mt--20">
                                        <Link className="btn-read-more" to="/contact"><span>Want to Know More?</span></Link>    
                                        {/* <a  onClick={}><span>Want to Know More?</span></a> */}
                                        </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
         
        </div>
      </div>
      </Layout>
  );
};

export default ServiceDetails;

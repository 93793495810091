import React, { useEffect, useState } from 'react';
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbOne from "../breadcrumb/BreadcrumbOne";
import SectionTitle from "../sectionTitle/SectionTitle";
import PortfolioOne from "./PortfolioOne";



const Portfolio = () => {
    // const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // useEffect(() => {
    //     const handleResize = () => setWindowWidth(window.innerWidth);
    //     window.addEventListener('resize', handleResize);
    //     return () => window.removeEventListener('resize', handleResize);
    // }, []);

    // const imageStyles = {
    //     width: '100%',
    //     maxWidth: '1200px',
    //     height: 'auto',
    //     ...(windowWidth < 768 && {
    //         maxWidth: '100%',
    //         width: 'auto',
    //     }),
    // };


    return (
        <>
            {/* <SEO title="Portfolio || Doob - React Business  Template" /> */}
            <SEO title="Novo Up"/>
            <Layout>

                {/* <BreadcrumbOne 
                    title="Empowering Success Through Innovative Branding And Digital Solutions With NovoUp."
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Portfolio"
                /> */}
                
                <div className="main-content">
                    <div className="rwt-portfolio-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                        <SectionTitle
                                            textAlign = "text-center"
                                            radiusRounded = ""
                                            subtitle = "Visual Triumphs"
                                            title = "Technology that Facilitates Enterprise Transformation!"
                                            description = ""
                                        />
                                </div>
                            </div>
                            <PortfolioOne Column="col-lg-6 col-md-6 col-sm-12 col-12 mt--30 portfolio" />
                            {/* <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <img 
                                    src="/images/portfolio/templet.jpeg"
                                    style={imageStyles}
                                    alt="Templet"
                                />
                            </div> */}

                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}
export default Portfolio;

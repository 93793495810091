import React from 'react';
import {Link} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const ProductList = [
    {
        image: './images/product/Streamlined-Payout-Processing.png',
        title: 'Streamlined Payout Processing',
        description: 'Automate calculations, saving time and minimizing errors.'
    },
    {
        image: './images/product/Group 84.png',
        title: 'Powerful Dashboards & Analytics',
        description: 'Gain real-time insights into sales performance, identify trends, & make data-driven decisions.'
    },
    {
        image: './images/product/Group 83.png',
        title: 'Predictive Pricing & Sales Optimization',
        description: 'Leverage data to optimize pricing strategies and maximize sales efficiency.'
    },
 
]
const ProductTwo = ({textAlign, cardStyle}) => {
    return (
        <div className="row row--15 product-wrapper">
              {ProductList.map( (val , i) => (
                <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                    <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                        <div className={`card-box ${cardStyle} ${textAlign}`}>
                            <div className="inner">
                                <div className="image">
                                    <Link to="#product">
                                        <img src={`${val.image}`} alt="card Images" className="small-image"/>
                                    </Link>
                                </div>
                                <div className="content">
                                    <h4 className="title mb--20"><Link to="#product" dangerouslySetInnerHTML={{__html: val.title}}></Link></h4>
                                    <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{__html: val.description}}></p>
                                    <Link className="btn-default btn-small btn-border" to="#product">Read More</Link>
                                </div>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}
export default ProductTwo;
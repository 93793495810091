import React from 'react';
import { FiActivity, FiCast, FiMap } from "react-icons/fi";
import {Link} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const ProductList = [
    {
        imgSrc: './images/product/Group.png',
        title: 'Increased Efficiency',
        description: 'Automate manual tasks & free up valuable resources'
    },
    {
        imgSrc: './images/product/Group2.png',
        title: 'Improved Accuracy',
        description: 'Eliminate human error in calculations and data analysis'
    },
    {
        imgSrc: './images/product/Vector.png',
        title: 'Enhanced Transparency',
        description: 'Provide clear visibility into commission structures and sales performance.'
    },
    {
        imgSrc: './images/product/Group3.png',
        title: 'Data-Driven Decision Making',
        description: 'Make informed strategic decisions based on real-time insights.'
    },
    {
        imgSrc: './images/product/Group4.png',
        title: 'Targeted Sales Campaigns',
        description: 'Design laser-focused campaigns to maximize impact.'
    },
    {
        imgSrc: './images/product/Group5.png',
        title: 'Increased Sales & Revenue',
        description: 'Optimize pricing strategies and boost sales efficiency.'
    },
]

const ProductOne = ({textAlign, productStyle}) => {
    return (
        <div className="row row--15 service-wrapper">
              {ProductList.map( (val , i) => (
                <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                    <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                        <div className={`service ${productStyle} ${textAlign}`}>
                            <div className="icon">
                               <img src={val.imgSrc}  className="product-image" />
                            </div>
                            <div className="content">
                                <h4 className="title w-600"><Link to="#service" dangerouslySetInnerHTML={{__html: val.title}}></Link></h4>
                                <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{__html: val.description}}></p>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}
export default ProductOne;
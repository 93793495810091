import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";


const SectionTitle = ({subtitle, title, description, textAlign, radiusRounded, portfolioTitle, portfolioDescription}) => {
    return (
        <div className={`section-title ${textAlign}`}>
            <ScrollAnimation 
            animateIn="fadeInUp"
            animateOut="fadeInOut"
            animateOnce={true}>
                <h4 className={`subtitle ${radiusRounded}`}><span className="theme-gradient" dangerouslySetInnerHTML={{__html: subtitle}}></span></h4>
            </ScrollAnimation>

            <ScrollAnimation 
            animateIn="fadeInUp"
            animateOut="fadeInOut"
            animateOnce={true}>
                <h4 className={`portfolioTitle w-400 mb--15`} dangerouslySetInnerHTML={{__html: portfolioTitle}}></h4>
            </ScrollAnimation>

            <ScrollAnimation 
            animateIn="fadeInUp"
            animateOut="fadeInOut"
            animateOnce={true}>
                {typeof portfolioDescription === 'string' ? (
                    <p className="portfolioDescription b1" dangerouslySetInnerHTML={{ __html: portfolioDescription }}></p>
                ) : (
                    <div className="portfolioDescription b1"><p>{portfolioDescription}</p></div>
                )}
            </ScrollAnimation>
            
            <ScrollAnimation 
            animateIn="fadeInUp"
            animateOut="fadeInOut"
            animateOnce={true}>
                <h2 className="title w-400 mb--15" dangerouslySetInnerHTML={{__html: title}}></h2>
            </ScrollAnimation>

            

            <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}>
                {typeof description === 'string' ? (
                    <p className="description b1" dangerouslySetInnerHTML={{ __html: description }}></p>
                ) : (
                    <div className="description b1">{description}</div>
                )}
            </ScrollAnimation>

            {/* <ScrollAnimation 
            animateIn="fadeInUp"
            animateOut="fadeInOut"
            animateOnce={true}>
                <p className="description b1" dangerouslySetInnerHTML={{__html: description}}></p>
            </ScrollAnimation> */}
        </div>
    )
}
export default SectionTitle;
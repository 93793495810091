import React, {useRef} from 'react';
import VideoTwo from '../video/VideoTwo';
import { FiCheck } from "react-icons/fi";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';


const PopupData = [
    {
        id: "01",
        image: "./images/bg/bg-image-4.jpg",
        popupLink: [
            'https://youtu.be/Kz94DNytgGU?si=8EWBSlB-Z3lepvBP',
        ],
    }
]



const AboutFour = ({image}) => {



   
    
    return (
        <div className="about-area about-style-4 rn-section-gap">
            <div className="container">
                <div className="row row--40 align-items-center">
                    <div className="col-lg-6">
                        {PopupData.map((item) => (
                            <div className="video-btn" key={item.id}>
                                <VideoTwo imagename={`${image}`} galleryItem={item} />
                            </div>
                        ))}
                    </div>
                    <div className="col-lg-6 mt_md--40 mt_sm--40">
                        <div className="content">
                            <div className="inner">
                                <h3 className="title">Revolutionize your <strong>Business model.</strong></h3>
                                <ul className="feature-list">
                                    <li>
                                        <div className="icon">
                                            <FiCheck />
                                        </div>
                                        <div className="title-wrapper">
                                            <h4 className="title">Embrace Digital Transformation</h4>
                                            <p className="text">Unlock the potential of your business by embracing digital transformation. Leverage cutting-edge technologies to streamline operations, enhance efficiency, and stay ahead in today's dynamic market.</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <FiCheck />
                                        </div>
                                        <div className="title-wrapper">
                                            <h4 className="title">Scalability and Sustainability</h4>
                                            <p className="text">Ensure your business is not just prepared for the present but is scalable for the future. Our solutions focus on creating a sustainable business model that evolves with industry trends and customer expectations.</p>
                                        </div>
                                    </li>
                                </ul>
                                <div className="about-btn mt--30">
                                    {/* <a className="btn-default" href="">About Our Doob</a> */}
                                    
                                      <a className="btn-default"  href="https://novoup-assets.s3.ap-south-1.amazonaws.com/NovoUpPortfolio.pdf" target="_blank" rel="noopener noreferrer">
                                          NovoUp Portfolio
                                      </a>
                                      
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutFour

import React from "react";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import "./Product.css";  // Assuming you will create a CSS file for styling
import ProductOne from "./ProductOne";  
import ProductTwo from "./ProductTwo";
import maskGroupImage from './productImages/Mask group.png'
import Te1Image from './productImages/te 1.png'
import Image2 from './productImages/7dd4df5329 1.png'
import Image3 from './productImages/Streamlined-Payout-Processing.png'
import Group84 from './productImages/Group 84.png'
import Group83 from './productImages/Group 83.png'
import Group16 from './productImages/Group 16.png'
import Group from './productImages/Group.png'
import Group2 from './productImages/Group2.png'
import VectorImage from './productImages/Vector.png'
import Group3 from './productImages/Group3.png'
import Group4 from './productImages/Group4.png'
import Group5 from './productImages/Group5.png'
import Group93 from './productImages/Group 93.png'

const Product = () => {
  return (
    <>
      <SEO title="Novo Up" />
      <Layout>
        <div className="main-container">
          <div className="rn-product-area rn-section-gap">
            <div className="container">
              <div className="row">
                <div className="box">
                  <div className="box-part left">
                  <h2>Multiply Your Sales Caliber</h2>
                   <p>Unveiling CALIBER, (Commission Automation & Leverage for Increased Business Efficiency & Revenue)
                     a revolutionary application designed to streamline commission calculations, empower data-driven sales strategies, 
                     and unlock hidden insights that propel your sales to new heights.
                    </p>
                  </div>
                  <div className="box-part right">
                    <img src={maskGroupImage} alt="Right Side Image" />
                  </div>
                </div>
              </div>
              <div className="row">
                <h2>Struggling with Complex Commissions and Sales Data?</h2>
               <div className="box">
                  <div className="box-part image">
                    <img src={Te1Image} alt="Left Side Image" />
                  </div>
                  <div className="box-part content">
                    <ul>
                      <li>Managing intricate commission structures</li>
                      <li>Extracting actionable insights from sales data</li>
                      <li>Manual calculations and errors</li>
                      <li>Lack of transparency in commission structures</li>
                      <li>Inefficient payout processing</li>
                    </ul>
                  </div>
                </div>
                   <h3>Empower your salesforce with Caliber, the revolutionary solution for streamlined calculations and data-driven insights</h3> 
              </div>
              <div className="row3">
               {/* <div className="box3"> */}
                {/* <div className="card3">
                    <img src={Image3} alt="Small Image 1" className="small-image" />
                      <h2>Streamlined Payout Processing</h2>
                       <p>Automate calculations, saving time and minimizing errors.</p>
                         <button>Read More</button>
                 </div>
                 <div className="card3">
                    <img src={Group84} alt="Small Image 2" className="small-image" />
                         <h2>Powerful Dashboards & Analytics</h2>
                         <p>Gain real-time insights into sales performance, identify trends, & make data-driven decisions.</p>
                          <button>Read More</button>
                </div>
                 <div className="card3">
                    <img src={Group83} alt="Small Image 3" className="small-image" />
                       <h2>Predictive Pricing & Sales Optimization</h2>
                       <p>Leverage data to optimize pricing strategies and maximize sales efficiency.</p>
                      <button>Read More</button>
                   </div> */}
             
                   <div className="rn-product-area rn-section-gap ">
                        <div className="container">
                        <ProductTwo 
                                cardStyle = "card-style-1"
                                textAlign = "text-start"
                              
                             />
                        </div>
                    </div>
                    {/* </div> */}
              </div>
              <div className="row4">
                <h2>Empower Your Business with Caliber's Benefits</h2>
               {/* <div className="box4">
               <div className="card4">
                 <div class="outer-image">
                      <img src={Group16} alt="Outer Image"/>
                      <img src={Group} alt="Inner Image" class="inner-image"/>
                 </div>
                      <h2>Increased Efficiency</h2>
                       <p>Automate manual tasks & free up valuable resources</p>
                    
                 </div>
                 <div className="card4">
                 <div class="outer-image">
                      <img src={Group16} alt="Outer Image"/>
                      <img src={Group2} alt="Inner Image" class="inner-image"/>
                 </div>
                         <h2>Improved Accuracy</h2>
                         <p>Eliminate human error in calculations and data analysis</p>
                       
                </div>
                 <div className="card4">
                 <div class="outer-image">
                      <img src={Group16} alt="Outer Image"/>
                      <img src={VectorImage} alt="Inner Image" class="inner-image"/>
                 </div>
                       <h2>Enhanced Transparency</h2>
                       <p>Provide clear visibility into commission structures and sales performance.</p>
                    
                   </div>
                </div>
                <div className="box4">
                   <div className="card4">
                   <div class="outer-image">
                      <img src={Group16} alt="Outer Image"/>
                      <img src={Group3} alt="Inner Image" class="inner-image"/>
                 </div>
                      <h2>Data-Driven Decision Making</h2>
                       <p>Make informed strategic decisions based on real-time insights.</p>
                       
                 </div>
                 <div className="card4">
                 <div class="outer-image">
                      <img src={Group16} alt="Outer Image"/>
                      <img src={Group4} alt="Inner Image" class="inner-image"/>
                 </div>
                         <h2>Targeted Sales Campaigns</h2>
                         <p>Design laser-focused campaigns to maximize impact.</p>
                       
                </div>
                 <div className="card4">
                 <div class="outer-image">
                      <img src={Group16} alt="Outer Image"/>
                      <img src={Group5} alt="Inner Image" class="inner-image"/>
                 </div>
                       <h2>Increased Sales & Revenue</h2>
                       <p>Optimize pricing strategies and boost sales efficiency.</p>
                     
                   </div> */}
                {/* </div> */}
              </div>


              <div className="rn-product-area rn-section-gap">
                     <div className="container">
                      <ProductOne 
                       productStyle = "service__style--1 icon-circle-style"
                        textAlign = "text-center"
                             />
                        </div>
                    </div>


              <div className="row5">
                 <h2>Beyond Complexities: Caliber Simplifies Commission Structures Across Industries</h2>
                 <h3 className="main">Insurance</h3>
              <div className="box5">
                   {/* <img src={Group93} alt="Centered Image" className="centered-image" /> */}
                   
                   <div className="box-part">
                   <div className="content">
                       <h3>Challenges :</h3>
                        <p>Complex commission structures for various insurance products.</p>
                        <h3>Caliber's Impact :</h3>
                        <p>Streamline commission calculations, identify high-value client segments</p>
                    </div>
                  </div>
                  <div className="image">
                   <img src={Image2} alt="Centered Image" className="centered-image" />
                  </div>
                </div>
             
             </div>
             <div className="row6">
                 <h2>Ready to Unlock Your Caliber?</h2>
              <div className="box6">
                 <p>
                 Contact NovoUp Technologies today to learn more about Caliber and how it can transform your business!
                 </p>
                </div>
             </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Product;

import React from "react";
import { Accordion, Card, Button } from "react-bootstrap";

const AccordionOne = ({ customStyle }) => {
  return (
    <Accordion
      className={`rn-accordion-style ${customStyle}`}
      defaultActiveKey="0"
    >
      <Card>
        <Card.Header>
          <Accordion.Toggle as={Button} variant="link" eventKey="0">
            Why Choose NovoUp?
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <Card.Body>
          NovoUp since it's inception 2 years ago has already worked 
          with Industry giants solving some of the biggest problems 
          in the Insurance and Real estate Markets. Our solutions 
          reduced process time by 92%. 
         If you're unsure, book a free consultation with our founder!
          </Card.Body>
        </Accordion.Collapse>
      </Card>

      <Card>
        <Card.Header>
          <Accordion.Toggle as={Button} variant="link" eventKey="1">
            What makes NovoUp the technology partner of choice for businesses of
            all sizes and industries?
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="1">
          <Card.Body>
            NovoUp Technologies is the trusted technology partner chosen by
            businesses across industries for our unrivaled expertise, innovative
            solutions, and commitment to client success. Our personalized
            approach, scalable solutions, and dedication to exceeding
            expectations make us the preferred choice for businesses of all
            sizes.
          </Card.Body>
        </Accordion.Collapse>
      </Card>

      <Card>
        <Card.Header>
          <Accordion.Toggle as={Button} variant="link" eventKey="2">
            Is your data secure with NovoUp?
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="2">
          <Card.Body>
            Yes, at NovoUp Technologies, we prioritize the security and
            confidentiality of our clients' data. We have robust security
            measures in place to safeguard against unauthorized access, data
            breaches, and other threats. Your data is in safe hands with us.
          </Card.Body>
        </Accordion.Collapse>
      </Card>

      <Card>
        <Card.Header>
          <Accordion.Toggle as={Button} variant="link" eventKey="3">
            How can You request a quote for Our services?
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="3">
          <Card.Body>
            To receive a quote, simply visit our "Contact" page and fill out the
            inquiry form. Our team will get back to you promptly with the
            information you need.
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};
export default AccordionOne;

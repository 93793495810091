import React from "react";
import ContactForm from "./ContactForm";
import GoogleMapStyle from "./GoogleMapStyle";
import { FiHeadphones, FiMail, FiMapPin } from "react-icons/fi";
import { FaWhatsapp } from "react-icons/fa";

const ContactOne = () => {
  return (
    <>
      <div className="row row--15">
        <div className="col-lg-12">
          <div className="rn-contact-address mt_dec--30">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-12">
                <div className="rn-address">
                  <div className="icon">
                    <FiHeadphones />
                  </div>
                  <div className="inner">
                    <h4 className="title">Contact Phone Number</h4>
                    <p>
                      <a href="https://wa.me/+919881401775?text=Hello ">
                        <FaWhatsapp />
                        +91 98814 01775
                      </a>
                    </p>
                    {/* <p><a href="tel:+222 222 222 333">+1 612 247 6042</a></p> */}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginRight: "2px",
                      }}
                    >
                      {" "}
                      <p >
                        <a
                          target="__blank"
                          href="https://wa.me/+16122476042?text=Hello"
                        >
                          <FaWhatsapp />
                          +1 612 2476042
                        </a>
                      </p>
                      <p>
                        <a
                          target="__blank"
                          href="https://wa.me/+919028064100?text=Hello"
                        >
                          <FaWhatsapp />
                          +91 9028064100
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-12">
                <div className="rn-address">
                  <div className="icon">
                    <FiMail />
                  </div>
                  <div className="inner">
                    <h4 className="title">Our Email Address</h4>
                    <p>
                      <a href="mailto:admin@gmail.com">contact@novoup.com</a>
                    </p>
                    <p>
                      <a href="mailto:ajay@novoup.com">ajay@novoup.com</a>
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-12">
                <div className="rn-address">
                  <div className="icon">
                    <FiMapPin />
                  </div>
                  <div className="inner">
                    <h4 className="title">Our Location</h4>
                    <p>
                      First Floor, Sailee Apartments, Caranzalem, Panjim, Goa
                      403 002
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row mt--40 row--15">
        <div className="col-lg-7">
          <ContactForm formStyle="contact-form-1" />
        </div>
        <div className="col-lg-5 mt_md--30 mt_sm--30">
          {/* <GoogleMapStyle /> */}
          <img
            className="w-100"
            src="./images/about/company-building.png"
            alt="About Images"
          />
        </div>
      </div>
    </>
  );
};
export default ContactOne;

import React from "react";
import SEO from "../common/SEO";
import HeaderTopNews from "../common/header/HeaderTopNews";
import HeaderOne from "../common/header/HeaderOne";
import FooterFour from "../common/footer/FooterFour";
import BrandThree from "../elements/brand/BrandThree";
import AboutFour from "../elements/about/AboutFour";
import ServiceOne from "../elements/service/ServiceOne";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import CounterUpFour from "../elements/counterup/CounterUpFour";
import TeamFour from "../elements/team/TeamFour";
import TimelineTwo from "../elements/timeline/TimelineTwo";
import Separator from "../elements/separator/Separator";
import HeaderTwo from "../common/header/HeaderTwo";
import ServiceTwo from "../elements/service/ServiceTwo";
import ServiceThree from "../elements/service/ServiceThree";
import FooterTwo from "../common/footer/FooterTwo";

import TestimonialOne from "../elements/testimonial/TestimonialOne";
import SectionTitleTwo from "../elements/sectionTitle/SectionTitleTwo";
import TechTitans from "../elements/advancePricing/TechTitans";

const AboutUs = () => {
  return (
    <>
      <SEO title="About Us" />
      <main className="page-wrapper">
        {/* <HeaderTopNews /> */}
        {/* <HeaderOne btnStyle="btn-small round btn-icon" HeaderSTyle="header-transparent" /> */}
        <HeaderTwo
          btnStyle="btn-small round btn-icon"
          HeaderSTyle="header-transparent"
        />

        {/* Start Slider Area  */}
        <div
          className="slider-area slider-style-1 height-850 bg_image"
          data-black-overlay="7"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/about_us_image-12.png)`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner pt--80 text-center">
                  <div>
                    <h3 className="rn-sub-badge">
                      <span className="theme-gradient">
                        The future is now. Embrace it.
                      </span>
                    </h3>
                  </div>
                  <h1 className="title display-one">
                    We enhance your business <br /> by using novelistic
                    solutions.
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Slider Area  */}

        {/* Start Service Area  */}
        <div className="service-area rn-section-gapTop">
          <div className="container">
            <div className="row">
              {/* <div className="col-lg-6">
                <div className="content">
                  <p className="fw-light  mb--10 ">We are NovoUp Technologies, an IT dynamic startup founded in
                  2022, with a passion for transforming businesses through
                  innovative technology. 
                  We strive to build and nurture strong relationships with our
                  clients and business partners, ensuring their success is at
                  the core of everything we do.</p>
                </div>
              </div> */}
              <div className=" col-lg-12">
                <p className=" text-center mb--10 ">
                  We are NovoUp Technologies, an IT dynamic startup founded in
                  2022, with a passion for transforming businesses through
                  innovative technology. We strive to build and nurture strong
                  relationships with our clients and business partners, ensuring
                  their success is at the core of everything we do. Driven by
                  our mission, we provide client-focused tech services,
                  maximizing IT investment returns. As a leading systems
                  integrator and tech provider, our vision is to offer
                  cutting-edge solutions for businesses, organizations, and
                  governments, propelling them towards success in the digital
                  landscape.
                </p>
              </div>
            </div>
          </div>
        </div>
      
        {/* End Service Area  */}

        {/* Start Brand Area  */}
        {/* <div className="rwt-brand-area pb--60 pt--30">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 mt--10">
                <BrandThree brandStyle="brand-style-2" />
                <TechTitans/>
              </div>
            </div>
          </div>
        </div> */}
        
          
          
        {/* End Brand Area  */}

        <Separator />
        <div className="rwt-pricingtable-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Diving Deeper into Our Tech Titans"
                                    title = "Meet the Co-Founders."
                                    description = ""
                                />
                            </div>
                        </div>
                        <div className="row mt--40">
                            <div className="">
                                <TechTitans />
                            </div>
                        </div>
                    </div>
                </div>
     

    

        {/* Start Service Area  */}
        {/* <div className="rn-service-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Our Offerings"
                  title="Services provide for you."
                  description="Tailored Solutions for Your Every Need: Explore Our Comprehensive Service Offerings."
                />
              </div>
            </div>

            <ServiceThree
              serviceStyle="service service__style--2 text-center mt--25"
              textAlign="text-center"
            /> */}
            {/* <ServiceOne
              serviceStyle="service__style--1 bg-color-blackest radius mt--25"
              textAlign="text-center"
            /> */}
          {/* </div>
        </div> */}
        {/* End Service Area  */}

        {/* <div className="rwt-timeline-area rn-section-gapBottom">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <TimelineTwo classVar="no-gradient" />
              </div>
            </div>
          </div>
        </div> */}

        <Separator />

        <AboutFour image="./images/about/thumbnail.png" />

        {/* Start Elements Area  */}
        <div className="rwt-counterup-area pb--100">
          <div className="container mt_dec--30">
            <CounterUpFour
              column="col-lg-3 col-md-6 col-sm-6 col-12"
              counterStyle="counter-style-4"
              textALign="text-center"
            />
          </div>
        </div>
        {/* End Elements Area  */}

        <Separator />

        {/* Start Elements Area  */}
        {/* <div className="rwt-team-area rn-section-gap">
          <div className="container">
            <div className="row mb--20">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Our Corporate Team."
                  title="Corporate Team Member."
                  description=""
                />
              </div>
            </div>
            <TeamFour
              column="col-lg-6 col-xl-3 col-md-6 col-12 mt--30"
              teamStyle="team-style-three"
            />
          </div>
        </div> */}
        {/* End Elements Area  */}

        <FooterTwo />
      </main>
    </>
  );
};

export default AboutUs;

import React from "react";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbOne from "../../elements/breadcrumb/BreadcrumbOne";
import BlogProp from "./itemProp/BlogProp";
import SectionTitle from "../../elements/sectionTitle/SectionTitle";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import ScrollAnimation from "react-animate-on-scroll";

const BlogGridView = ({ serviceStyle, textAlign }) => {
  return (
    <>
      {/* <SEO title="Blog List || Doob - React Business  Template" /> */}
      <SEO title="NovoUp" />
      <Layout>
        {/* <BreadcrumbOne 
                    title="The Easiest Way To Create Website <br /> Quick Copy & Make site."
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Blog List"
                /> */}
        {/* <div className="main-content"> */}
        {/* Start Blog Area  */}
        {/* <div className="rn-blog-area rn-section-gap">
                        <div className="container">
                            <div className="row mt_dec--30">
                                <BlogProp column="col-lg-4 col-md-6 col-12 mt--30" StyleVarProp="box-card-style-default" />
                            </div>
                        </div>
                    </div> */}
        {/* End Blog Area  */}
        {/* </div> */}
        <div className="main-content">
          {/* Start Blog Area */}
          <div className="rn-blog-area rn-section-gap">
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-6">
                  <SectionTitle
                    portfolioTitle="How Automation and AI are Transforming the Insurance Market."
                    subtitle="Impact Of Ai and Automation on Insuarance Market"
                    portfolioDescription="The insurance market is rapidly evolving, but it faces significant challenges. Insurance providers, brokers, aggregators, and agents all struggle with the complexities of commissions and long payment cycles. NovoUp Technologies' innovative software, CALIBER, is already easing these burdens for leading insurance companies. Here's how."
                    textAlign="text-center"
                    radiusRounded=""
                  />
                  <SectionTitle
                    subtitle="Overcoming the hurdles and challenges"
                    textAlign="text-center"
                    radiusRounded=""
                    portfolioTitle="The Challenge of Complex Commissions."
                    portfolioDescription="One of the biggest problems in the insurance industry is managing complex commission structures. Insurance providers, brokers, aggregators, and agents often navigate various commission plans for different policies, leading to confusion and errors. Insurers, on the other hand, find it difficult to track these numerous commission structures, often resulting in delayed payments and dissatisfied partners. These complexities can lead to operational inefficiencies and financial inaccuracies, impacting the overall service quality."
                  />
                  <SectionTitle
                    subtitle="Reducing the Delay in Payments"
                    textAlign="text-center"
                    radiusRounded=""
                    portfolioTitle="The Burden of Long Payment Times."
                    portfolioDescription="Long payment cycles are another significant issue. Whether it’s paying out claims or processing commissions, the wait times can be exasperating. These delays can strain relationships between insurance providers, brokers, aggregators, agents, and policyholders. For policyholders, long waits for claim settlements can be particularly stressful, especially during emergencies when quick financial assistance is crucial. Prolonged payment times can erode trust and satisfaction, making policyholders hesitant to rely on their insurance providers."
                  />
                </div>
                <div className="col-12 col-md-6">
                  <SectionTitle
                    subtitle="Dive Into CALIBER"
                    textAlign="text-center"
                    radiusRounded=""
                    portfolioTitle="Enter CALIBER: Simplifying Insurance Operations For Better Results."
                    portfolioDescription={
                      <div>
                        <p className="">
                          NovoUp Technologies’ new software, CALIBER, is
                          designed to address these challenges directly. Here’s
                          how it makes a difference:
                        </p>
                        <ul>
                          <li>
                            <strong style={{ textDecoration: "underline" }}>
                              Streamlined Payouts:
                            </strong>{" "}
                            <p className="">
                              CALIBER accelerates the payment process, ensuring
                              that commissions and claims are paid promptly and
                              accurately. This speed and precision help maintain
                              positive relationships and reduce frustration for
                              insurance providers, brokers, aggregators, agents,
                              and policyholders.
                            </p>
                          </li>
                          <li>
                            <strong style={{ textDecoration: "underline" }}>
                              Predictive Pricing:
                            </strong>{" "}
                            <p>
                              Using advanced algorithms, CALIBER predicts
                              pricing trends, enabling insurers to set
                              competitive and fair prices. This proactive
                              approach helps manage policies more effectively
                              and stay ahead in the market, benefiting all
                              stakeholders with better pricing and more
                              transparent policies.
                            </p>
                          </li>
                          <li>
                            <strong style={{ textDecoration: "underline" }}>
                              User-Friendly Dashboard:
                            </strong>{" "}
                            <p>
                              CALIBER features an intuitive dashboard that
                              provides clear and easy access to necessary
                              information for insurance providers, brokers,
                              aggregators, agents, and policyholders. This
                              transparency reduces errors and keeps everyone
                              informed, giving policyholders peace of mind
                              knowing their information is accurate and
                              up-to-date.
                            </p>
                          </li>
                        </ul>

                        <strong style={{ textDecoration: "underline" }}>
                          Trusted by Industry Leaders
                        </strong>

                        <p>
                          CALIBER is not just a newcomer in the market; it is
                          already trusted by some of the top insurance
                          companies. These industry leaders have integrated
                          CALIBER into their operations, witnessing significant
                          improvements in efficiency and customer satisfaction.
                        </p>
                        <p>
                          These companies have found that CALIBER not only
                          simplifies complex commission structures and reduces
                          payment times but also enhances overall operational
                          efficiency. The positive impact on their business
                          processes underscores the software’s effectiveness and
                          reliability.
                        </p>
                      </div>
                    }
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-6">
                  {" "}
                  <SectionTitle
                    subtitle="Capability of CALIBER"
                    textAlign="text-center"
                    radiusRounded=""
                    portfolioTitle="Expanding Horizons"
                    portfolioDescription="In the coming months, NovoUp Technologies plans to extend CALIBER’s capabilities to any distribution-led business, including FMCG, auto loans, and mutual funds—any industry that involves commission payments to partners."
                  />
                </div>

                <div className="col-12 col-md-6">
                  {" "}
                  <h4 className={`subtitle`}>
                    <Link className="btn-read-more" to="/contact">
                      <span className="theme-gradient">want a demo?</span>
                    </Link>
                  </h4>
                  <div className="inner">
                    <div
                      className="details-list-description"
                      style={{
                        fontSize: "25px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "8px",
                        textAlign: "justify",
                      }}
                    >
                      <p style={{}}>
                        Interested in seeing how CALIBER can transform your
                        business? Get a free demo today! Contact us to learn
                        more and experience the benefits firsthand.
                      </p>
                    </div>
                  </div>
                  {/* <SectionTitle
                    subtitle="Want a Demo?"
                    textAlign="text-center"
                    radiusRounded=""
                    demoTitle="Get a Free Demo"
                    // portfolioTitle=""
                    portfolioDescription="Interested in seeing how CALIBER can transform your business? Get a free demo today! Contact us to learn more and experience the benefits firsthand."
                  /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};
export default BlogGridView;

import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom";
import Typed from "react-typed";

const AboutOne = () => {
  return (
    <div className="rwt-about-area rn-section-gap">
      <div className="container">
        <div className="row row--30 align-items-center">
          <div className="col-lg-5">
            <div className="thumbnail">
              <img
                className="w-100"
                src="./images/about/company-building.png"
                alt="About Images"
              />
            </div>
          </div>

          <div className="col-lg-7 mt_md--40 mt_sm--40">
            <div className="content">
              <div className="section-title">
                <h2 className="title">
                  <Typed
                    className="theme-gradient"
                    strings={["Innovate", "Transform", "Succeed"]}
                    typeSpeed={80}
                    backSpeed={5}
                    backDelay={1000}
                    loop
                  />
                  <br />{" "}
                  {/* <Typed
                    className="theme-gradient"
                    strings={["Innovate.", "Transform.", "Succeed."]}
                    typeSpeed={80}
                    backSpeed={5}
                    backDelay={1000}
                    loop
                  /> */}
                </h2>
                <p>
                  {/* Tools aren't as essential as solutions. We offer a team with
                  the knowledge, insight, and IT solutions you need to assist
                  you in making wise business decisions so that you can scale
                  more quickly, maximize your investments, and accomplish your
                  objectives. */}
                  NovoUp Technologies takes immense pride in its journey of
                  success and accomplishment. Throughout our years of operation,
                  we have consistently delivered exceptional solutions to our
                  esteemed clients, enabling them to thrive in the ever-evolving
                  business landscape. Our commitment to excellence and customer
                  satisfaction has garnered us a reputation as a trusted
                  partner.
                </p>
                <p>
                  {/* At NovoUp we believe that the key to success is delivering the
                  most outstanding service and products, with integrity and
                  accountability, at every level. We take pride in our values
                  and strive to create a positive workplace where employees are
                  empowered to achieve their full potential. */}
                  By harnessing the power of cutting-edge technologies and
                  leveraging our deep domain expertise, we have successfully
                  helped numerous businesses overcome their challenges and
                  achieve their goals. From implementing advanced technologies
                  to drive data-driven decision-making, to ensuring robust
                  cybersecurity, NovoUp continues to be at the forefront of
                  driving growth and success for our clients.
                </p>
                <div className="read-more-btn mt--40">
                  <Link className="btn-default" to="/about-us">
                    <span>Our Story Hub</span>
                  </Link>
                  {/* <a className="btn-default" href="#"><span>More About Us</span></a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutOne;

import React from "react";
import Typed from "react-typed";
import { Link } from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import AccordionOne from "../accordion/AccordionOne";
import { FaQuestion } from "react-icons/fa";

const mission = () => {
  return (
    <div className="rn-company-mission-are rn-section-gap">
      <div className="container">
        <div className="row row--30">
          <div className="col-lg-6">
            <div className="mission-title">
              <h2 className="title">
                <Typed
                  className="theme-gradient"
                  strings={[
                    // "To Deliver Novelistic Technological Solutions that are centered on our client's needs rather than our own is our team's unifying goal.",
                    "Our Mission",
                    // "Vission.",
                    // "Agency.",
                  ]}
                  typeSpeed={80}
                  backSpeed={5}
                  backDelay={1000}
                  loop
                />
              </h2>
              <p style={{ fontSize: "20px" }}>
                To be a client-focused provider of technology services and
                consulting that enables its clients to maximize the return on
                their IT investments .
              </p>
              <h2 className="title">
                <Typed
                  className="theme-gradient"
                  strings={[
                    // "To Deliver Novelistic Technological Solutions that are centered on our client's needs rather than our own is our team's unifying goal.",
                    "Our Vision",
                    // "Vission.",
                    // "Agency.",
                  ]}
                  typeSpeed={80}
                  backSpeed={5}
                  backDelay={1000}
                  loop
                />
              </h2>
              <p style={{ fontSize: "20px" }}>
                Systems integrator and technology provider founded to provide
                businesses, organizations, and governments with cutting-edge
                intelligent technical solutions.
              </p>

              {/* <p>Consectetur adipisicing elit. Quia quaerat dolore laboriosam ea minus dignissimos, laudantium odit officia soluta, et deleniti fugit deserunt saepe, velit sapiente obcaecati? Vitae, iusto delectus?</p> */}

              <div className="read-more-btn mt--50">
                <Link className="btn-default btn-icon" to="/contact">
                  Any Questions
                  <i className="icon">
                    <FaQuestion />
                  </i>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-6 mt_md--30 mt_sm--30">
            <AccordionOne customStyle="" />
          </div>
        </div>
      </div>
    </div>
  );
};
export default mission;

import React from "react";
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";
import ServiceData from "../../data/service/ServiceData.json";

const ServiceThree = ({ textAlign, serviceStyle }) => {
  return (
    <div className="row row--15 service-wrapper">
      {ServiceData.map((val, i) => (
        <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={val.id}>
          <ScrollAnimation
            animateIn="fadeInUp"
            animateOut="fadeInOut"
            animateOnce={true}
          >
            <div className={`service ${serviceStyle} ${textAlign}`}>
              <div className="inner">
                <div className="image">
                <Link
                      to={`/ServiceDetails/${val.id}`}
                      // dangerouslySetInnerHTML={{ __html: val.title }}
                    ><img src={`${val.image}`} alt="card Images" /></Link>
                  
                </div>
                <div className="content">
                  <h4 className="title">
                    <Link
                      to={`/ServiceDetails/${val.id}`}
                      dangerouslySetInnerHTML={{ __html: val.title }}
                    ></Link>
                  </h4>
                  <p
                    className="description b1 color-gray mb--0"
                    dangerouslySetInnerHTML={{ __html: val.shortdescription }}
                  ></p>
                </div>
              </div>
            </div>
          </ScrollAnimation>
        </div>
      ))}
    </div>
  );
};

export default ServiceThree;

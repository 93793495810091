import React from "react";
import Typed from "react-typed";
import SEO from "../common/SEO";
import { Link } from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import HeaderTwo from "../common/header/HeaderTwo";
import HeaderTopNews from "../common/header/HeaderTopNews";
import FooterTwo from "../common/footer/FooterTwo";
import Copyright from "../common/footer/Copyright";
import AboutOne from "../elements/about/AboutOne";
import ServiceFive from "../elements/service/ServiceFive";
import CalltoActionFive from "../elements/calltoaction/CalltoActionFive";
import TeamTwo from "../elements/team/TeamTwo";
import TestimonialThree from "../elements/testimonial/TestimonialThree";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import Separator from "../elements/separator/Separator";
import Mission from "../elements/about/Mission";
import BlogList from "../components/blog/itemProp/BlogList";
import BlogClassicData from "../data/blog/BlogList.json";
import ServiceThree from "../elements/service/ServiceThree";
import BrandThree from "../elements/brand/BrandThree";
var BlogListData = BlogClassicData.slice(0, 3);

const BusinessConsulting2 = () => {
  return (
    <>
      <SEO title="Novo Up" />
      <main className="page-wrapper">
        {/* <HeaderTopNews /> */}
        <HeaderTwo btnStyle="round" HeaderSTyle="header-not-transparent" />

        {/* Start Slider Area  */}
        <div className="slider-area slider-style-1 bg-transparent height-850">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner text-center">
                  <span className="subtitle">ONE-STOP NOVELISTIC IT SOLUTIONS</span>
                  <h1 className="title theme-gradient display-two">
                    NovoUp <br />{" "}
                    </h1>
                    <div className='theme-gradient display-two' >
                    <Typed
                      strings={[
                        "AI-Driven Business Solutions",
                        "IoT and Real-Time Data Analytics",
                        "Cloud Computing and SaaS Products",
                        "Cybersecurity Enhancements",
                        "Custom Software Development",
                        " Managed Services",
                        "Application Transformation",
                       
                      ]}
                      typeSpeed={80}
                      backSpeed={5}
                      backDelay={1000}
                      loop
                    />
                    </div>
                 <div className="mt-4"> <p className="description">
                  Make your lives simpler with Code. We design IT solutions 
                  tailor-made to address any business challenge. From optimizing 
                  processes to enhancing lead generation, and making payment 
                  collections more accessible, our solutions are crafted to 
                  meet your specific needs and drive your success...
                  </p></div>
                 
                  {/* <div className="button-group"> */}
                    {/* <a className="btn-default btn-medium round btn-icon" target="_blank" to="/portfolio">Browse Our Creations <i className="icon"><FiArrowRight /></i></a> */}
                    {/* <Link
                      className="btn-default btn-medium round btn-icon"
                      to="/portfolio"
                    >
                      Browse Our Creations{" "}
                      <i className="icon">
                        <FiArrowRight />
                      </i>
                    </Link>
                    <Link
                      className="btn-default btn-medium btn-border round btn-icon"
                      to="/contact"
                    >
                      Book a consultation
                      <i className="icon">
                        <FiArrowRight />
                      </i>
                    </Link>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Slider Area  */}

        <Separator />

        <Separator />
        {/* Start Service Area  */}
        <div className="rn-service-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Innovative Solutions Tailored for You"
                  title="A Spectrum of Our Innovative Solutions."
                  description="Ignite your success with our expert blend of innovation and dedication. Experience tailored solutions that align perfectly with your unique needs, unleashing a realm of possibilities for your business."
                />
              </div>
            </div>
            {/* <ServiceThree
              serviceStyle="service service__style--2 text-center mt--25"
              textAlign="text-center"
            /> */}
            {/* <ServiceFive 
                            serviceStyle = "gallery-style"
                            textAlign = "text-start"
                        /> */}
          </div>
        </div>
        {/* End Service Area  */}

        {/* Start About Area  */}
        <AboutOne />
        {/* End About Area  */}

        {/* Start Call To Action Area  */}
        {/* <div className="rwt-callto-action-area">
                    <div className="wrapper">
                        <CalltoActionFive />
                    </div>
                </div> */}
        {/* End Call To Action Area  */}

        {/* Start Team Area  */}
        {/* <div className="rwt-team-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Our Experts."
                                        title = "Our Company Experts."
                                        description = "We provide company and finance service for <br /> startups and company business."
                                    />
                            </div>
                        </div>
                        <TeamTwo column="col-lg-4 col-md-6 col-12" teamStyle="team-style-default style-two" />
                    </div>
                </div> */}
        {/* End Team Area  */}
        <Separator />
        {/* Start Mission Area   */}
        <Mission />
        {/* Start Mission Area  */}

        <Separator />
        {/* Start Testimonial Area  */}
        {/* <div className="rwt-testimonial-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Client Feedback"
                  title="Our Client's." */}
                  {/* // description = "We provide company and finance service for <br /> startups and company business." */}
                {/* />
              </div>
            </div>
            <TestimonialThree teamStyle="" /> */}
            {/* <BrandThree brandStyle="brand-style-2" /> */}
          {/* </div>
        </div> */}
        {/* End Testimonial Area  */}

        <Separator />
        {/* <div className="blog-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Latests News"
                                    title = "Our Latest News."
                                    description = "We provide company and finance service for <br /> startups and company business."
                                />
                            </div>
                        </div>
                        <div className="row row--15">
                            {BlogListData.map((item) => (
                                <div key={item.id} className="col-lg-4 col-md-6 col-12 mt--30">
                                    <BlogList StyleVar="box-card-style-default" data={item} />
                                </div>
                            ))}
                        </div>
                    </div>
                </div> */}
        <FooterTwo />
        <Copyright />
      </main>
    </>
  );
};
export default BusinessConsulting2;

import React from "react";
import Slider from "react-slick";
import { slickDot } from "../../utils/script";

const TestimonialData = [
 
  {
    // form: "Germany",
    description:
      "NovoUp’s Data Intelligence services revolutionized our marketing efforts, providing valuable insights and enabling data-driven decisions that elevated our strategies. With improved conversion rates, ROI, and customer engagement, their services have been a game-changer. Highly recommended for data-driven marketing success.",
    // name: "John Due",
    // subtitle: "App Developer",
    image: "policyboss-PhotoRoom.png-PhotoRoom (1)",
  },
  {
    // form: "USA",
    description:
      "NovoUp has transformed my restaurant business, optimizing operations from inventory management to online ordering. With innovative solutions, we've increased efficiency, reduced costs, and delighted customers. Their expertise & personalized approach have attracted a growing customer base. Highly recommend for remarkable results. ",
    // name: "Janen",
    // subtitle: "App Developer",
    image: "gshcllogo",
  },
];
const TestimonialThree = ({ teamStyle }) => {
  return (
    <div className="row">
      <div className="col-lg-12">
        <Slider
          className="slick-space-gutter--15 rn-slick-dot rn-slick-arrow mb--60"
          {...slickDot}
        >
          {TestimonialData.map((data, index) => (
            <div key={index} className={`testimonial-style-two ${teamStyle}`}>
              <div className="row align-items-center row--20">
                <div className="order-2 order-md-1 col-lg-6 col-md-8 offset-lg-1">
                  <div className="content mt_sm--40">
                    <span className="form">{data.form}</span>
                    <p className="fs-1 description">{data.description}</p>
                    <div className="client-info">
                      <h4 className="title">{data.name}</h4>
                      <h6 className="subtitle">{data.subtitle}</h6>
                    </div>
                  </div>
                </div>
                <div className="order-1 order-md-2 col-lg-4 col-md-4">
                  <div className="thumbnail">
                    <img
                      className="w-100"
                      src={`./images/testimonial/${data.image}.png`}
                      alt="Corporate React Template"
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};
export default TestimonialThree;
